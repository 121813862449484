<template>
  <tr :class="{ 'promotions__disabled-promotion': !props.item.status }">
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left">
      <strong>
        {{props.item.name}}
      </strong>
    </td>
    <td class="justify-left">
      <div class="business-category__name" :style="{ background: props.item.type.color}">
        <v-icon color="white">{{props.item.type.icon}}</v-icon>
        <span style="padding-left: 10px;">{{props.item.type.name || '- - -'}}</span>
      </div>
    </td>
    <td class="justify-left">
      <strong v-if="props.item.type.code == 'fixed-value-discount' || props.item.type.code == 'fixed-value-addition'">
        {{ props.item.value | money }}
      </strong>
      <strong v-else>
        {{ props.item.value }}%
      </strong>
    </td>
    <td class="justify-center" :style="{cursor: config.mode != 'select' ? 'pointer' : ''}" @click="openPerson()">
      <span v-if="props.item.status == 1" class="promotions__status promotions__status--active">
        Ativo
      </span>
      <span v-else class="promotions__status promotions__status--inactive">
        Inativo
      </span>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'BusinessCategories',
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  .business-category__name {
    text-align: left;
    color: white;
    padding: 3px 10px;
    font-size: 18px;
    margin-right: 30px;
    border-radius: 5px;
    border-style: dotted;
    border-width: 1px;
  }
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .promotions__disabled-promotion {
    background: lightgray;
  }
  .promotions__status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .promotions__status--active {
    color: #eceff1;
    background: rgb(4, 112, 0);
  }
  .promotions__status--inactive {
    color: grey;
    background: #eceff1;
  }
</style>